import $ from 'jquery';
import 'slick-carousel';

$(document).ready(function(){
  $(".section-quotes").slick({
    centerMode: true,
    dots: true,
    focusOnSelect: true,
    infinite: true,
    rows: 0, // Fix for extra divs in v1.8.0-1
    initialSlide: 1,
    slidesToShow: 1,
    variableWidth: true,
  });
});
