document.addEventListener("DOMContentLoaded", onShowPageReady);

function onShowPageReady() {
  if(!document.querySelector("body.page-job")) { return };

  bindStickyJobActions({
    popIntoPlaceEl: document.querySelector("[data-sel-pop-into-place]"),
    footerEl: document.querySelector("[data-sel-footer]")
  });
}

function bindStickyJobActions({popIntoPlaceEl, footerEl}) {
  let scrolling = false,
      stickyActionsHeight,
      windowHeight,
      footerOffset,
      targetOffset;

  if(!popIntoPlaceEl || !footerEl) { return; }

  calculateHeights();
  popIntoPlace();

  window.addEventListener('resize', calculateHeights);
  window.addEventListener('scroll', popIntoPlace);

  ////

  function popIntoPlace(e){
    if(scrolling) { return; }

    scrolling = true;

    window.requestAnimationFrame(function() {
      scrolling = false;
      if(window.scrollY >= targetOffset) {
        popIntoPlaceEl.classList.remove("is-embedded")
      } else {
        popIntoPlaceEl.classList.add("is-embedded")
      }
    });
  }

  function calculateHeights(e){
    elementHeight = popIntoPlaceEl.offsetHeight;
    windowHeight = window.innerHeight;
    footerOffset = footerEl.offsetTop;
    targetOffset = footerOffset - windowHeight;
  }
}
