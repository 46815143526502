document.addEventListener("DOMContentLoaded", onJobsSearchReady);

function onJobsSearchReady() {
  if(!document.querySelector("body.scope-is-jobs-search")) { return };

  // Jobs Search Form
  document.querySelector("select#credentials").addEventListener('change', function(){
    // Submit the jobs search form when the credential changes,
    // so that the career_specialties menu is populated for the selected credential.
    // If a specialty is selected, and is shared across credentials, it will
    // remain selected (see MenuPresenter#selected_values).
    document.querySelector("[data-sel=job-search-form]").submit();
  });
}
